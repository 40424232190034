export enum MthTitle {
  LTI = 'LTI',
  CLEAR_CACHE = 'Clear Cache',
  ASSIGN_NEW_SOE = 'Assign new SoE',
  CLOSE = 'Close',
  DOWNLOAD = 'Download',
  DOWNLOAD_ICON = 'Download Icon',
  SEARCH_DOTS = 'Search...',
  RESULTS = 'Results',
  SHOW = 'Show',
  DESCRIPTION = 'Description',
  COURSE_TYPE = 'Course Type',
  TEACHER = 'Teacher',
  SELECT_ALL = 'Select All',
  NEW = 'New',
  RETURNING = 'Returning',
  SCHEDULE_REPORTS = 'Schedules',
  SCHOOL_PARTNER_REPORTS = 'School Partner Reports',
  APPLICATIONS_REPORTS = 'Applications',
  MASTER_REPORTS = 'Master',
  SCHOOL_YEAR = 'School Year',
  APPLICATIONS = APPLICATIONS_REPORTS,
  YEAR = 'Year',
  MID_YEAR = 'Mid-year',
  MID_YEAR_APPLICATION = 'Mid-year Application',
  ENROLLMENT_PACKETS = 'Enrollment Packets',
  STATE = 'State',
  STATE_LOGO = 'State Logo',
  PROGRAM = 'Program',
  COUNTIES = 'Counties',
  SCHOOL_DISTRICTS = 'School Districts',
  GRADES = 'Grades',
  GRADES_REQUIRED = 'Grades Required',
  PROGRAM_YEAR_REQUIRED = 'Program Year Required',
  BIRTHDAY_CUT_OFF = 'Birthday Cut-off',
  SPECIAL_ED = 'Special Ed',
  SPECIAL_EDUCATION = 'Special Education',
  SHOW_ARCHIVED = 'Show Archived',
  HIDE_ARCHIVED = 'Hide Archived',
  ADD_ANNOUNCEMENT = 'Add Announcement',
  ADD_POPUP = 'Add Popup',
  ADD_ASSESSMENT = 'Add Assessment',
  NEW_ASSESSMENT = 'New Assessment',
  TEST_NAME = 'Test Name',
  INFORMATION = 'Information',
  OPTION_1 = 'Option 1',
  ADD_OPTION = 'Add Option',
  ADD_NEW = 'Add New',
  UNSAVED_TITLE = 'Unsaved Changes',
  NOTIFICATION = 'Notification',
  UNSAVED_DESCRIPTION_DO = 'Are you sure you want to exit without sending this email? The status of "Order" will not be maintained',
  UNSAVED_DESCRIPTION = 'Are you sure you want to leave without saving changes?',
  PROGRAM_SETTINGS = 'Program Settings',
  SCHEDULES = SCHEDULE_REPORTS,
  DIPLOMA_SEEKING = 'Diploma-seeking',
  NON_DIPLOMA_SEEKING = 'Non Diploma-seeking',
  DIPLOMA_SEEKING_PATH = 'Diploma-seeking Path',
  TESTING_PREFERENCE = 'Testing Preference',
  APPLICATION_QUESTIONS = 'Application Questions',
  ENROLLMENT_QUESSTONS = 'Enrollment Questions',
  IMMUNIZATIONS = 'Immunizations',
  MID_YEAR_SCHEDULES = 'Mid-year Schedules',
  SCHEUDLE_BUILDER = 'Schedule Builder',
  SECOND_SEMESTER = '2nd Semester',
  SECOND_SEMESTER_STARTS = '2nd Semester Starts',
  HOMEROOM_RESOURCES = 'Homeroom Resources',
  HOMEROOM_RESOURCE = 'Homeroom Resource',
  SCHEDULE = 'Schedule',
  STEP_TESTING_PREFERENCE = 'Testing',
  STEP_OPT_OUT_FORM = 'Opt',
  STEP_DIPLOMA_SEEKING = 'Diploma',
  STEP_SCHEDULE_BUILDER = SCHEDULE,
  REDUCES_FUNDS = 'Reduces Funds',
  ON_SITE_SPLIT_ENROLLMENT = 'On-site Split Enrollment',
  SAVE_CHANGES = 'Save Changes',
  ACCEPT = 'Accept',
  ACCEPT_AS_SECOND_SEMESTER = 'Accept as 2nd semester',
  SAVE_DRAFT = 'Save Draft',
  REQUEST_UPDATES = 'Request Updates',
  SUBMIT = 'Submit',
  SUBMIT_UPDATES = 'Submit Updates',
  SUBMIT_REQUEST_RESOURCES = 'Submit Requests',
  CONFIRM_RESOURCES = 'Are you sure you want to leave without submitting your request for Homeroom Resources?',
  LEARNING_LOGS = 'Learning Logs',
  EOY_LEARNING_LOGS = 'End of Year Learning Log Deadline',
  DIRECT_ORDER_REIMBURSEMENTS_INFO = 'Direct Order & Reimbursement Information',
  REIMBURSEMENTS = 'Reimbursements',
  DIRECT_ORDERS = 'Direct Orders',
  FIRST_SEMESTER = '1st Semester',
  FIRST_SEMESTER_ENDS = '1st Semester Ends',
  GRADE_TRANSITION = '12th Grade Transition Date',
  GRADE_TRANSITION_ENABLE = 'Grade Transition',
  SUPPLEMENTAL_LEARNING_FUNDS = 'Supplemental Learning Funds',
  TECHNOLOGY = 'Technology',
  CUSTOM_BUILT = 'Custom-built',
  PASSWORD_HINT = 'Passwords must contain 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.',
  DIRECT_ORDERS_REIMBURSEMENTS = 'Direct Orders & Reimbursements',
  DELETE_PACKET_TITLE = 'Delete Packet',
  DELETE_PACKET_DESCRIPTION = 'Deleting the student packet will revert their status to blank. They will need to re-apply. Are you sure you want to delete the packet(s)?',
  BTN_DELETE = 'Delete',
  TECHNOLOGY_ALLOWANCE = 'Technology Allowance',
  MY_TECH_HIGH_DIRECT = 'My Tech High Direct',
  THIRD_PARTY_PROVIDER = '3rd Party Provider',
  REQUIRED_SOFTWARE = 'Required Software',
  REIMBURSEMENT_FORMS = 'Reimbursement Forms',
  DIRECT_ORDER_FORMS = 'Direct Order Forms',
  REQUIRE_UPDATES = 'Require Updates',
  UPDATES_REQUIRED = 'Updates Required',
  ALLOW_UPDATES = 'Allow Updates',
  DASHBOARD = 'Dashboard',
  HOMEROOM = 'Homeroom',
  SETTINGS = 'Settings',
  QUICK_LINKS = 'Quick Links',
  USERS = 'Users',
  USERS_REQUIRED = 'Users Required',
  REPORTS = 'Reports',
  RECORDS = 'Records',
  ENROLLMENT = 'Enrollment',
  CURRICULUM = 'Curriculum',
  CALENDAR = 'Calendar',
  ANNOUNCEMENTS = 'Announcements',
  ANNOUNCEMENT_POPUPS = 'Announcement Popups',
  STUDENTS = 'Students',
  SHOW_GRADUATED_COMPLETED = 'Show Graduated/Completed',
  COMMUNICATION = 'Communication',
  PARENT_LINK = 'Parent Link',
  PARENT = 'Parent',
  REPORT_TITLE = 'Report title',
  SELECTED_COLUMNS = 'Selected Columns',
  ADD_CUSTOM_COLUMN = '+ Add Custom Column',
  ADD_COLUMNS = 'Add Columns',
  COLUMN_TITLE = 'Column Title',
  COLUMN_DATA = 'Column Data',
  REQUIRED = 'Required',
  ENTRY = 'Entry',
  SEARCH = 'Search',
  SEARCH_VIEW_ALL = 'Search title or message',
  EDIT = 'Edit',
  DELETE = BTN_DELETE,
  MOVE = 'Move',
  UPLOAD_FILE = 'Upload File',
  MAX_UPLOAD_FILE_SIZE = '(Maximum of 20MB)',
  FILTER = 'Filter',
  CLEAR_ALL = 'Clear All',
  SELECT_YEAR = 'Select Year',
  DIRECT_DEDUCTION = 'Add Direct Deduction',
  SUM = 'Sum',
  CANCEL = 'Cancel',
  DELETE_REQUEST = 'Are you sure you want to delete this request?',
  SEND = 'Send',
  SUBJECT = 'Subject',
  SUBJECT_TITLE = 'Subject Title',
  FROM_EMAIL = 'From: email in template',
  FROM = 'From',
  STATE_COURSE_CODES = 'State Course Codes',
  STATE_CODE = 'State Code',
  STATE_CODES = 'State Codes',
  TITLE_ID = 'Title ID',
  TITLE = 'Title',
  BLANK = 'Blank',
  IMPORT = 'Import',
  FAMILY_REIMBURSEMENT_TITLE = 'Direct Orders & Requests for Reimbursement',
  MY_TECH_HIGH = 'My Tech High',
  MTH = 'MTH',
  OPENED = 'OpenEd',
  TECH_TREP_ACADEMY = 'Tech Trep Academy',
  ADDITIONAL_INFORMATION = 'Additional Information',
  AMOUNT = 'Amount',
  TYPE = 'Type',
  STUDENT = 'Student',
  STUDENT_ID = 'Student ID',
  PROGRAM_YEAR = 'Program Year',
  STATUS = 'Status',
  GRADE_LEVEL = 'Grade Level',
  CURRICULUM_PROVIDER = 'Curriculum Provider',
  CURRENT_SOE = 'Current SoE',
  PREVIOUS_SOE = 'Previous SoE',
  SCHOOL_DISTRICT = 'School District',
  SOE_PREFERENCE = 'SoE Preference',
  SCHOOL_OF_ENROLLMENT = 'School of Enrollment',
  NOTES = 'Notes',
  ASSIGN = 'Assign',
  SOE = 'SoE',
  STUDENT_LEGAL_NAME = 'Student Legal',
  STUDENT_PREFERRED_NAME = 'Student Preferred',
  GRADE = 'Grade',
  STATUS_DATE = 'Status Date',
  PERIOD_CATEGORY = 'Period Category',
  PERIOD = 'Period',
  COURSE_CODE = 'Course Code',
  ORDER = 'Order',
  CONFIRMATION = 'Confirmation:',
  ORDER_CONFIRMATION = 'Order Confirmation',
  SCHEDULE_IS_REQUIRED = 'Schedule is Required',
  SUBMIT_NOW = 'Submit Now',
  RESUBMIT_NOW = 'Resubmit Now',
  STUDENT_LEGAL_LAST_FIRST = 'Student Legal Last, First',
  STUDENT_PREFERRED_LAST_FIRST = 'Student Preferred Last, First',
  MTH_PROVIDER = 'MTH Provider',
  OPENED_PROVIDER = 'OpenEd Provider',
  PROVIDER_COURSE = 'Provider Course',
  CUSTOM_DESCRIPTION = 'Custom Description',
  CREATE_HYPERWALLET_ACCOUNT = 'Create a Hyperwallet Account',
  SCHEDULE_STATUS = 'Schedule Status',
  HW_SYNC_TITLE = 'Hyperwallet Sync',
  HW_SYNC_MESSAGE = 'Approved Request for Reimbursements are sent to Hyperwallet every Monday and Thursday at 3 am MT for payments',
  HW_SYNC_APPROVED_MESSAGE = 'Current Sum of Approved Request to be sent for',
  AVERAGE = 'Average',
  LAUNCHPAD = 'Launchpad',
  OTHER = 'Other',
  PROVIDERS = 'Providers',
  TESTING_OPT_IN = 'Testing Opt-in',
  TESTING_OPT_OUT = 'Testing Opt-out',
  FEEDBACK = 'Feedback',
  WARNING = 'Warning',
  ATTENTION = 'Attention',
  VIEW = 'View',
  EDIT_RESUBMIT = 'Edit & Resubmit',
  LATE = 'Late',
  CLEAR_FORMATTING = 'Clear Formatting',
  DEFAULT_QUESTION = 'Default Question',
  QUESTION = 'Question',
  ADDITIONAL_QUESTIONS = 'Additional Questions',
  DISCLAIMER_TEXT = 'Disclaimer Text',
  TEXT_MESSAGE = 'I can receive critical, program-specific text messages via this number.',
  VALIDATION = 'Validation',
  DISPLAY_FOR_ADMIN = 'Display for Admin',
  OK = 'Ok',
  ERROR_PROCESSING_APPLICATION = 'There was an error when processing applications',
  PROCESSING_APPLICATIONS = 'Processing Applications',
  PROCESSING_APPLICATIONS_SUBTITLE = "Processing applications... it might take 10 seconds per application, please don't close this browser or tab while processing.",
  SESSION_EXPIRED = 'Your session has expired',
  LOGIN_AGAIN = 'Please log in again',
  SEARCH_TITLE = 'Search title, message, or student',
  FIRST_SEMESTER_AVERAGE = '1st Semester Average',
  SECOND_SEMESTER_AVERAGE = '2nd Semester Average',
  NUMBER_OF_ZEROS = '# of Zeros',
  ZEROS_FIRST_SEMESTER = '# of Zeros in 1st semester',
  ZEROS_SECOND_SEMESTER = '# of Zeros in 2nd semester',
  NUMBER_OF_EXCUSED = '# of Excused',
  NUMBER_OF_EXCUSED_LOGS = '# of Excused Logs',
  MIN_NUBMER = 'Min Number',
  BELOW = 'Below',
  DATE = 'Date',
  ADDED_TO_HOMEROOM = 'Added to Homeroon on or after',
  STUDENT_STATUS = 'Student Status',
  USERNAME = 'Username',
  ORIGINALLY_SUBMITTED = 'Originally Submitted',
  STUDENT_DETAILS = 'Student Details',
  RESUBMIT = 'Resubmit',
  PENDING_APPROVAL = 'Pending Approval',
  PREFERRED_FIRST_NAME = 'Preferred First Name',
  PREFERRED_LAST_NAME = 'Preferred Last Name',
  ENROLLMENT_PACKET = 'Enrollment Packet',
  STUDENT_EMAIL = 'Student Email',
  EMAIL_IN_USE = 'This email is already being used.',
  DELETE_IMAGE = 'Delete Image',
  DELETE_CONFIRMATION = 'Are you sure you want to delete this image',
  STUDENT_ACCOUNT = 'Student Account',
  ACCOUNT_SUBTITLE = "An email will be sent to the student's email address. They will need to verify their account and create a password before they have access.",
  INTENT_TO_RE_ENROLL = 'Intent to Re-enroll',
  INTENT_TO_RE_ENROLL_UPCOMING_SCHOOL_YEAR = 'Intent to Re-enroll for Upcoming School Year',
  NOTIFY_OF_WITHDRAW = 'Notify of Withdraw',
  SUBMIT_APPLICATION = 'Submit Application',
  ADD_ANOTHER_STUDENT = 'Add Another Student',
  INDICATE_REASON = 'Please indicate the reason:',
  NONE = 'None',
  SECONDARY_EMAIL = 'Secondary Email',
  DEFAULT_QUESTIONS = 'Default Questions',
  ITR_SUPPORT = 'Please reach out to parent support if you wish to change your response from Yes to No',
  DAYS_BEFORE_CLOSE_DATE = ' (Days before Close Date)',
  DAYS_BEFORE_DEADLINE = ' (Days before deadline)',
  UPDATE_BANNER_TEXT = 'The version of the InfoCenter App you are using is currently out of date and needs to be updated.',
  UPDATE_BANNER_BTN = 'Update Now',
  DELETE_ANNOUNCEMENT_POPUPS = 'Delete Announcement Popups',
  DELETE_ANNOUNCEMENT_POPUPS_CONTENT = 'Are you sure you want to delete this Announcement Popup?',
  CANCEL_CHANGES_ANNOUNCEMENT_POPUS_MESSAGE = 'Are you sure you want to cancel changes?',
  CONFIRM_PUBLISH_ANNOUNCEMENT_POPUPS = 'Do you want to publish this announcement now?',
  YES = 'Yes',
  NO = 'No',
  CANCEL_CHANGES = 'Cancel Changes',
  ADD_ANNOUNCEMENT_POPUP = 'Add Announcement Popup',
  EDIT_ANNOUNCEMENT_POPUP = 'Edit Announcement Popup',
  PUBLISH = 'Publish',
  SAVE = 'Save',
  SAVE_DRAFT_DISABLED = 'You must submit the required updates to proceed.',
  TRANSFER_STUDENT = 'Transfer Student',
  EDIT_STUDENT_RESPONSES = 'Edit Student Responses',
  WEEKLY_LL = 'This Weekly Learning Log has been submitted.',
  STUDENT_RESPONSES_SAVED = 'Student responses are saved',
  VIEW_ALL = 'View All',
  READ_MORE = 'Read More',
  RSVP = 'RSVP',
  EVENTS = 'Events',
  CONGRATULATIONS = 'Congratulations!',
  YOU_ARE_ALL_CAUGHT_UP = 'You are all caught up.',
  EMAIL_HISTORY = 'Email History',
  STUDENT_RECORD_FILE_REQUESTED = 'Student Record File Requested',
  FILE_REQUESTED_DESCRIPTION = "The file you requested will be sent to your email once it's ready.",
  FILE_REQUESTED_FAIL = 'An error occurred while requesting file',
  PARTICIPATION = 'Participation',
  PARTICIPATION_ALERT = 'Submitting a Learning Log before all participation days have been checked will result in absences for those days.',
  WITHDRAWAL_WITHDRAWN_ALERT = "A withdrawal in status 'Withdrawn' is selected",
  WITHDRAWAL_WITHDRAWN_ALERT_SUB = "You can only withdraw students with withdrawals in status 'Notified' or 'Requested'",
  DISPLAY_RESULTS = 'Display results for',
  DISABLE_RESET_SCHEDULE = 'This schedule cannot be reset because there is a reimbursement or direct order associated with one of its periods.',
}
