import { atom } from 'recoil'
import { EMPTY_STRING } from '@mth/constants'
import {
  defaultGradeFilter,
  defaultStatusFilter,
} from '@mth/screens/Admin/Reimbursements/ReimbursementRequests/defaultValues'
import { FilterVM } from '@mth/screens/Admin/ResourceRequests/Filters/type'
import { ConfirmDialogType } from './Store.type'
import { RegionType } from '../UserContext/types'

export const loadingState = atom<boolean>({
  key: 'loading',
  default: false,
})

export const editModeLearningLogState = atom<boolean>({
  key: 'editMode',
  default: false,
})

export const saveLearningLogState = atom<boolean>({
  key: 'saveLearningLog',
  default: false,
})

export const studentRegionFromTeacherState = atom<{ regionId: number; regionName: string }>({
  key: 'studentRegionFromTeacher',
  default: { regionId: 1, regionName: EMPTY_STRING },
})

export const reloadComponentState = atom<boolean>({
  key: 'reloadComponent',
  default: false,
})

export const confirmDialogBeforeLeave = atom<ConfirmDialogType>({
  key: 'confirmDialogBeforeLeave',
  default: {
    activate: false,
    header: EMPTY_STRING,
    content: EMPTY_STRING,
  },
})

export const modalConfirmDialogBeforeLeave = atom<boolean>({
  key: 'modalConfirmDialogBeforeLeave',
  default: false,
})

export const regionSelectedDialogBeforeLeave = atom<RegionType | null>({
  key: 'regionSelectedDialogBeforeLeave',
  default: null,
})

export const regionChangeCallback = atom<(() => void) | null>({
  key: 'regionChangeCallback',
  default: null,
})

export const isLoggingOut = atom<boolean>({
  key: 'isLoggingOut',
  default: false,
})

export const filterReimbursementRequestState = atom<FilterVM | undefined>({
  key: 'filterReimbursementRequestState',
  default: {
    statuses: defaultStatusFilter,
    grades: defaultGradeFilter,
  },
})

export const searchReimbursementRequestState = atom<string>({
  key: 'searchReimbursementRequestState',
  default: '',
})
