import React, { useContext } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider as ApProvider, concat, HttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useFlag } from '@unleash/proxy-client-react'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import { isNil } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { EPIC_1582_STORY_4078, EPIC_3303_TASK_4391 } from '@mth/constants'
import { MthRoute, QUERY_PARAMETERS } from '@mth/enums'
import { logoutUser, refreshToken } from '@mth/services/jwt-session/jwt-session.service'
import { AuthContext } from '../AuthProvider/AuthContext'
import { UserContext } from '../UserContext/UserProvider'

const MILISECONDS = 1000
const isTokenExpired = (token: string) => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(token)
    const currentTime = Math.floor(Date.now() / MILISECONDS)
    return decodedToken.exp && decodedToken.exp < currentTime
  } catch (error) {
    console.error('there was an error validating token expiration')
    console.error(error)
    return true
  }
}

type ApolloProviderProps = {
  children: React.ReactNode
  apolloNetworkstatusLink: ApolloLink
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children, apolloNetworkstatusLink }) => {
  const epic_1582_story_4078 = useFlag(EPIC_1582_STORY_4078)
  const { credentials, signOut, setCredentials } = useContext(AuthContext)
  const history = useHistory()
  const { setMe } = useContext(UserContext)
  const location = useLocation()
  const currentPath = location.pathname
  const epic3303Story4391 = useFlag(EPIC_3303_TASK_4391)

  const logout = async () => {
    if (epic3303Story4391) {
      await logoutUser()
    }
    setMe(null)
    signOut()

    history.push({
      pathname: MthRoute.DASHBOARD,
      search: new URLSearchParams({ [QUERY_PARAMETERS.EXPIRATION]: currentPath }).toString(),
    })
  }

  const getToken = () => {
    if (typeof credentials === 'string') return credentials
    else if (!isNil(localStorage.getItem('masquerade'))) return localStorage.getItem('masquerade')
    else return localStorage.getItem('JWT')
  }

  const authLink = setContext(async (_, { headers }) => {
    let token = getToken()
    if (token && isTokenExpired(token)) {
      if (epic3303Story4391) {
        token = await refreshToken(setCredentials, logout)
        if (!token || isTokenExpired(token)) {
          await logout()
          console.warn('Token still expired after refresh')
        }
      } else {
        await logout()
        console.warn('Token expired and no refresh available')
      }
    }
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const httpLink = new HttpLink({ uri: import.meta.env.VITE_PUBLIC_API_URL })

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: epic_1582_story_4078
      ? apolloNetworkstatusLink.concat(concat(authLink, httpLink))
      : concat(authLink, httpLink),
  })

  return <ApProvider client={client}>{children}</ApProvider>
}
