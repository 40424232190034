import { isNil, isString } from 'lodash'
import { EMPTY_STRING } from '@mth/constants'
import { StudentsGradeLevel, MthTitle } from '@mth/enums'
import { Student } from '@mth/models'
import { Person } from '@mth/screens/HomeroomStudentProfile/Student/types'

export const getTeacherProfilePhoto = (avatarUrl: string | undefined): string => {
  if (!avatarUrl) return EMPTY_STRING

  const s3URL = import.meta.env.VITE_PUBLIC_BASE_S3_IMAGE_URL
  return `${s3URL}${avatarUrl}`
}

export const getProfilePhoto = (person: Person): string => {
  if (!person?.photo) return EMPTY_STRING

  const s3URL = import.meta.env.VITE_PUBLIC_BASE_S3_IMAGE_URL
  return s3URL + person.photo
}

export const getStringInitialLetter = (name: string): string => {
  if (isString(name) && name.trim().length > 0) {
    name = name.trim()
    return name[0].trim().toUpperCase()
  } else {
    return EMPTY_STRING
  }
}

export const formatStudentGrade = (gradeLevel: string | undefined): string => {
  if (!gradeLevel) return EMPTY_STRING
  // @ts-ignore
  if (gradeLevel === StudentsGradeLevel.KINDERGARTEN) return `${gradeLevel}`
  if (gradeLevel === StudentsGradeLevel.FIRST) return `${gradeLevel}st ${MthTitle.GRADE}`
  if (gradeLevel === StudentsGradeLevel.SECOND) return `${gradeLevel}nd ${MthTitle.GRADE}`
  if (gradeLevel === StudentsGradeLevel.THIRD) return `${gradeLevel}rd ${MthTitle.GRADE}`
  return `${gradeLevel}th ${MthTitle.GRADE}`
}

export const getStudentGradeLevelBySY = (student: Student | undefined, school_year_id: number | undefined): string => {
  if (isNil(student) || isNil(school_year_id)) return EMPTY_STRING
  const findGrade = student.grade_levels.find(
    (gradeLevel) => Number(gradeLevel.school_year_id) === Number(school_year_id),
  )
  return findGrade ? findGrade.grade_level : EMPTY_STRING
}
